<template>
  <v-row>
    <v-col sm="6">
      <v-row>
        <v-col sm="12">
          <v-card>
            <v-card-title class="align-start">
              Account Details
              <v-spacer />
              <v-btn :color="suspended" class="text--white mr-4" @click="toggleSuspend">
                <span v-if="data.suspend"> APPROVE </span>
                <span v-else> SUSPEND </span>
              </v-btn>
              <v-btn color="warning" class="text--white mr-4"
                :to="{ name: 'accounts-change-password', params: { id: user_id } }">
                Change Password
              </v-btn>
              <v-btn color="primary" class="text--white mr-4" :to="{ name: 'accounts-edit', params: { id: user_id } }">
                Edit
              </v-btn>
              <v-btn color="error" class="text--white" @click="deactivate()"> Deactivate </v-btn>
            </v-card-title>
            <v-card-text class="text-no-wrap text--primary body-1">
              <v-row v-for="(value, key) in data" :key="key" class="py-1" dense>
                <template v-if="!['rep_id', 'postage_spent', 'password'].includes(key)">
                  <v-col v-if="key == 'shipper_id'" sm="5"> PB | Shipper ID </v-col>
                  <v-col v-else-if="key == 'email_verified_at'" sm="5"> Verified Email </v-col>
                  <v-col v-else-if="key == 'survey'" sm="5"> How did you find us? </v-col>
                  <v-col v-else sm="5">
                    {{ key | titleCase('_') }}
                  </v-col>
                  <v-col v-if="['last_login', 'created_at'].includes(key)">
                    {{ value | readableDate(getTz()) }}
                  </v-col>
                  <v-col v-else-if="key == 'integrations'" sm="7">
                    <div class="d-flex align-center flex-wrap" style='gap: 12px; width: 100%'>
                      <div v-for="integ in value" :key="integ.id">
                        <img :src="`/integrations/${integ.code}/table-icon.png`" height="35" />
                        <suffix v-if="integ.integration_count > 1">{{ integ.integration_count }}</suffix>
                      </div>
                    </div>
                  </v-col>
                  <v-col v-else-if="['email_verified_at'].includes(key)">
                    <div v-if="value">
                      <v-icon color="success">mdi-check</v-icon>
                    </div>
                    <div v-else>
                      <v-icon color="error">mdi-close</v-icon>
                      <!-- <v-btn small color="primary" @click="activateAccount">Activate Now</v-btn> -->
                    </div>
                  </v-col>
                  <v-col v-else>
                    {{ value }}
                  </v-col>
                  <v-col sm="12">
                    <v-divider />
                  </v-col>
                </template>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col sm="12">
          <v-card>
            <v-card-title class="align-start">
              Activities
              <v-spacer />
              <v-btn color="primary" class="text--white mr-4" @click="showAddActivityModal">
                Add
              </v-btn>
            </v-card-title>
            <v-card-text class="text-no-wrap text--primary body-1" style="max-height: 500px; overflow-y: overlay">
              <v-list-item v-if="this.notes">
                <v-list-item-content class="pa-0">
                  {{ this.notes }}
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-for="activity in activities" :key="activity.id">
                <v-list-item-content class="pa-0">
                  <div>
                    {{ activity.note }}
                    <v-icon class="text-sm cursor-pointer"
                      @click="showEditActivityModal(activity)">mdi-file-edit-outline</v-icon>
                    <v-icon class="text-sm cursor-pointer ml-3"
                      @click="showConfirmDeleteActivity(activity.id)">mdi-close</v-icon>
                  </div>
                  <small>
                    {{ activity.admin_name }} - {{ formatDate(activity.created_at) }}
                    <i v-if="activity.updated_at" :title="formatDate(activity.updated_at)"> Edited</i>
                  </small>
                </v-list-item-content>
              </v-list-item>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-col>

    <v-col sm="6">
      <v-row>
        <v-col sm="12">
          <v-card>
            <v-card-text class="text-no-wrap text--primary body-1">
              <v-row class="pt-2" dense>
                <v-col sm="4">Label Printed</v-col>
                <v-col>{{ label_printed }}</v-col>
                <v-col sm="12">
                  <v-divider />
                </v-col>
              </v-row>
              <v-row class="pt-2" dense>
                <v-col sm="4">Postage Balance</v-col>
                <v-col>
                  <div v-if="postage_loading">
                    <v-progress-circular indeterminate :size="20" :width="3" color="primary"></v-progress-circular>
                  </div>
                  <div v-else>
                    {{ postage_balance }}
                  </div>
                </v-col>
                <v-col sm="12">
                  <v-divider />
                </v-col>
              </v-row>
              <v-row class="pt-2" dense>
                <v-col sm="4">Postage Spent</v-col>
                <v-col>{{ postage_spent }}</v-col>
                <v-col sm="12">
                  <v-divider />
                </v-col>
              </v-row>
              <v-row class="pt-2" dense>
                <v-col sm="4">Refunded</v-col>
                <v-col>{{ refunded }}</v-col>
                <v-col sm="12">
                  <v-divider />
                </v-col>
              </v-row>
              <v-row class="pt-2" dense>
                <v-col sm="4">Shipsurance Balance</v-col>
                <v-col>{{ total_insurance }}</v-col>
                <v-col sm="12">
                  <v-divider />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col sm="12">
          <v-card>
            <v-card-text class="text-no-wrap text--primary body-1">
              <v-row v-for="(value, key) in subscription" :key="key" class="pt-2" dense>
                <v-col sm="4">
                  {{ key | titleCase('_') }}
                </v-col>
                <v-col>
                  {{ value }}
                </v-col>
                <v-col sm="12">
                  <v-divider />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col sm="12">
          <v-card>
            <v-card-title class="align-start">
              Reps Attached To Account
              <v-spacer />
              <v-btn color="primary" class="text--white mr-4" @click="showAddUSPS = true">
                Add USPS Rep
              </v-btn>
            </v-card-title>
            <v-card-text class="text-no-wrap text--primary body-1">
              <v-list-item v-for="rep in reps" :key="rep.id">
                <v-list-item-content class="pa-0">
                  <v-col>
                    <a color="primary" :href="'/usps/' + rep.id" style="text-decoration: none;">{{ rep.name }}</a>
                  </v-col>
                  <v-col style="display: flex; justify-content: space-between;">
                    <div @click="copyToClipboard(rep.email)" style="cursor: pointer;">{{ rep.email }} <v-icon
                        class="text-lg">mdi-file-outline</v-icon></div>
                    <button @click="showConfirmDeleteUSPSRep(rep.id)">
                      <v-icon class="text-sm cursor-pointer">mdi-close</v-icon>
                    </button>
                  </v-col>
                </v-list-item-content>
              </v-list-item>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-col>

    <v-dialog v-model="showConfirmDeleteActivityDialog" persistent width="550">
      <v-card>
        <v-card-title>Delete Activity</v-card-title>

        <v-container>
          <h3>Are you sure you want to delete this Activity?</h3>
        </v-container>

        <v-card-actions class="justify-end">
          <v-btn color="primary" @click="removeActivity()">Yes</v-btn>
          <v-btn color="error" @click="hideConfirmDeleteActivity">No</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showConfirmDeleteUSPSRepDialog" persistent width="550">
      <v-card>
        <v-card-title>Delete USPS Rep</v-card-title>

        <v-container>
          <h3>Are you sure you want to delete this USPS Rep to this account?</h3>
        </v-container>

        <v-card-actions class="justify-end">
          <v-btn color="primary" @click="removeUSPSRep()">Yes</v-btn>
          <v-btn color="error" @click="hideConfirmDeleteUSPSRep">No</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showConfirmDeleteUSPSRepDialog" persistent width="550">
      <v-card>
        <v-card-title>Delete USPS Rep</v-card-title>

        <v-container>
          <h3>Are you sure you want to delete this USPS Rep to this account?</h3>
        </v-container>

        <v-card-actions class="justify-end">
          <v-btn color="primary" @click="removeUSPSRep()">Yes</v-btn>
          <v-btn color="error" @click="hideConfirmDeleteUSPSRep">No</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- <v-col sm="4">
      <v-card>
        <v-card-title>Download Fiscal Year Aggregate</v-card-title>
        <v-container class="card-max-height">
          <v-btn class="primary" disabled>Download</v-btn>
        </v-container>
      </v-card>
    </v-col> -->

    <!-- <v-col sm="12" v-if="first_labels.length">
      <v-card>
        <v-list>
          <v-list-group>
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>View First Labels</v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item v-for="label in first_labels" :key="label.id" :href="label.pdf" target="_blank">
              <v-list-item-content>
                <v-list-item-title v-text="label.name"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
        </v-list>
      </v-card>
    </v-col> -->

    <v-col sm="6" v-if="first_labels.length">
      <v-card>
        <v-card-title> View First Labels </v-card-title>
        <v-card-text>
          <v-list-item v-for="label in first_labels" :key="label.id" :href="label.pdf" target="_blank">
            <v-list-item-content>
              <v-list-item-title v-text="label.name"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-card-text>
      </v-card>
    </v-col>


    <!-- <v-col sm="12" v-if="reps.length">
      <v-card>
        <v-list>
          <v-list-group>
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>Reps Attached To Account</v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item v-for="rep in reps" :key="rep.id" link @click="goToRep(rep.id)">
              <v-list-item-content class="pa-0">
                <v-col>{{ rep.name }}</v-col>
                <v-col>{{ rep.email }}</v-col>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
        </v-list>
      </v-card>
    </v-col> -->

    <v-dialog v-model="filterMenu" persistent width="550">
      <v-card>
        <v-card-title>
          Filters
          <v-btn class="ml-auto" icon color="error" @click="filterMenu = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-container class="pa-5">
          <v-row>
            <!-- <v-col sm="12">
              <v-select
                :items="[
                  { value: null, text: 'UTC+00:00'},
                  { value: 'America/New_York', text: 'Eastern Standard Time'},
                  { value: 'America/Chicago', text: 'Central Standard Time'},
                  { value: 'America/Denver', text: 'Mountain Standard Time'},
                  { value: 'America/Los_Angeles', text: 'Pacific Standard Time'},
                ]"
                label="Select Timezone"
                v-model="selectedTimezone"
                hide-details
                outlined
                dense
              >
              </v-select>
            </v-col> -->
            <v-col sm="12">
              <v-row class="align-center">
                <span class="mx-3">Date Range</span>
                <date-range-picker v-model="dateRange" :appendToBody="true" :showDropdowns="true" :max-date="getMaxDate"
                  :ranges="ranges" @toggle="toggleDateRange">
                </date-range-picker>
                <v-btn color="warning" class="ml-2" @click="dateRange = { startDate: null, endDate: null }">
                  Clear
                </v-btn>
              </v-row>
            </v-col>
            <v-col sm="12">
              <v-select v-model="selectedClasses" :items="service_classes" label="Filter Service Class" :menu-props="{
                value: serviceClassMenu,
                maxHeight: 400,
              }" hide-details clearable multiple outlined dense @click:clear="selectedClasses = []"
                @click="serviceClassMenu = true">
                <template v-slot:prepend-item>
                  <v-list-item ripple @click="toggleServiceClass">
                    <v-list-item-action>
                      <v-icon :color="selectedClasses.length > 0 ? 'indigo darken-4' : ''">
                        {{ icon }}
                      </v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title> Select All </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider class="mt-2"></v-divider>
                </template>
                <template v-slot:selection="{ item }">
                  <v-chip>
                    <span>{{ getCode(item) }}</span>
                  </v-chip>
                </template>
                <template v-slot:append-item>
                  <v-divider class="mb-2"></v-divider>
                  <v-btn small text color="success" class="ml-2" @click="serviceClassMenu = false">Confirm</v-btn>
                </template>
              </v-select>
            </v-col>
          </v-row>
        </v-container>

        <v-card-actions class="justify-end">
          <v-btn color="primary" @click="update">Apply</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showAddUSPS" persistent width="550">
      <v-card>
        <v-card-title>Add USPS</v-card-title>

        <v-container>
          <VAutocomplete v-model="selectedUSPSRep" :items="uspsReps" label="Select USPS Rep" hide-details outlined>
          </VAutocomplete>
        </v-container>

        <v-card-actions class="justify-end">
          <v-btn color="warning" @click="showAddUSPS = false">Cancel</v-btn>
          <v-btn color="primary" @click="addUSPSRep">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showAddActivity" persistent width="550">
      <v-card>
        <v-card-title>Add Activity</v-card-title>

        <v-container>
          <VTextarea v-model="activityNote" placeholder="Enter a note" outlined />
        </v-container>

        <v-card-actions class="justify-end">
          <v-btn color="warning" @click="showAddActivity = false">Cancel</v-btn>
          <v-btn color="primary" @click="addActivity">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showEditActivity" persistent width="550">
      <v-card>
        <v-card-title>Edit Activity</v-card-title>

        <v-container>
          <VTextarea v-model="activity.note" placeholder="Enter a note" outlined />
        </v-container>

        <v-card-actions class="justify-end">
          <v-btn color="warning" @click="showEditActivity = false">Cancel</v-btn>
          <v-btn color="primary" @click="editActivity">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-col sm="12">
      <v-tabs v-model="tab" centered>
        <div class="filters">
          <v-btn color="primary" @click="filterMenu = true">
            <v-icon class="mr-2">mdi-filter</v-icon>
            Filter
          </v-btn>
        </div>
        <v-tabs-slider></v-tabs-slider>
        <v-tab href="#account-report"> Report </v-tab>
        <v-tab href="#account-orders"> Orders </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item value="account-report">
          <v-container fluid>
            <v-row>
              <v-col sm="12">
                <vue-apex-charts class="custom-apex-chart" v-if="showChart" :options="chartOptions" :series="series"
                  height="400" />
              </v-col>
              <v-col sm="12">
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th>Service Class</th>
                        <th>Spend</th>
                        <th>Pieces</th>
                        <th>Insurance</th>
                        <th>Revenue</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(s, i) in services" :key="i">
                        <td>{{ s.name }}</td>
                        <td>{{ s.spend }}</td>
                        <td>{{ s.pieces }}</td>
                        <td>{{ s.insurance }}</td>
                        <td>{{ s.revenue }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>
        <v-tab-item value="account-orders">
          <data-table title="Order" url-name="labels" :headers="order_headers" :items="orders" :options.sync="options"
            :loading.sync="loading" :page="orderPage" :pageCount="orderPageCount" :items-length="orderTotalItems"
            :items-per-page="50" :hide-add-btn="true" :timezone="getTz()" @update="fetchData" scrollRef="orderTable" />
        </v-tab-item>
      </v-tabs-items>
    </v-col>
  </v-row>
</template>

<style lang="scss" scoped>
.filters {
  >button {
    position: absolute;
    left: 7px;
    top: 5px;
  }
}

.card-max-height {
  max-height: 192px;
  overflow-y: auto;
}

.text-overflow {
  white-space: normal;
  max-height: 75px;
  overflow-y: overlay;

  &::-webkit-scrollbar {
    width: 6px;
  }
}
</style>

<script>
import { mapGetters } from 'vuex'

import AccountMixin from '@/mixins/account-mixin'
import FilterMixin from '@/mixins/filter-mixin'
import DataTable from '@/components/data-table/DataTable.vue'

import moment from 'moment-timezone'
import axios from 'axios'
import _ from 'lodash'

import DateRangePicker from 'vue2-daterange-picker'
import VueApexCharts from 'vue-apexcharts'

export default {
  mixins: [AccountMixin, FilterMixin],
  components: {
    DataTable,
    DateRangePicker,
    VueApexCharts,
  },

  data() {
    return {
      pageTitle: 'Account',
      showAddUSPS: false,
      showAddActivity: false,
      showEditActivity: false,
      showConfirmDeleteUSPSRepDialog: false,
      showConfirmDeleteActivityDialog: false,
      notes: null,
      uspsReps: [],
      activities: [],
      tab: null,
      data: {},
      reps: [],
      options: {},
      orders: [],
      subscription: null,
      searchTerm: '',
      postage_loading: false,
      label_printed: 0,
      postage_balance: 0,
      postage_spent: 0,
      refunded: 0,
      total_insurance: 0,
      services: null,
      first_labels: [],
      order_headers: [
        // { text: 'Actions', value: 'actions', align: 'center', sortable: false },
        // {
        //   text: 'ID',
        //   align: 'start',
        //   sortable: false,
        //   value: 'id',
        // },
        { text: 'Tracking Number', value: 'tracking' },
        { text: 'Order Number', value: 'order_number' },
        { text: 'Marketplace', value: 'marketplace' },
        { text: 'Insurance', value: 'insurance_price', align: 'end', width: 110 },
        { text: 'Rate', value: 'rate', align: 'end' },
        { text: 'Signature', value: 'signature', align: 'end', width: 110 },
        { text: 'Surcharge', value: 'surcharge', align: 'end', width: 110 },
        { text: 'Total Cost', value: 'total_cost', align: 'end', width: 110 },
        { text: 'Service Class', value: 'code' },
        // { text: 'Return', value: 'return', align: 'center', sortable: false },
        { text: 'Shipment Date', value: 'created_at' },
      ],
      orderPage: 1,
      orderPageCount: 0,
      orderTotalItems: 0,
      showChart: false,
      series: [],
      chartOptions: {},
      desiredOrder: [
        'First-Class Package International Service',
        'Ground Advantage',
        'Media Mail',
        'Priority Mail',
        'Priority Mail Express',
        'Priority Mail Express International',
        'Priority Mail International',
        'Scan-Based Return',
      ],
    }
  },

  watch: {
    pageTitle(newTitle) {
      document.title = newTitle;
    }
  },

  computed: {
    ...mapGetters(['getTz']),
    suspended() {
      console.log('SUSPENDED: ', this.data.suspend)
      return this.data.suspend ? 'success' : 'error'
    },
  },

  mounted() {
    this.fetchUSPSRep()
    this.fetchActivities()
  },

  methods: {
    formatDate(date) {
      return moment(date).format('MMMM D, YYYY, h:mm A')
    },

    async fetchUSPSRep() {
      try {
        const token = this.$store.state.token
        const url = process.env.VUE_APP_API_URL

        const { data: { data: uspsReps } } = await axios.get(`${url}/api/all-reps`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        this.uspsReps = uspsReps.map(u => {
          let name = u.name ?? ''
          if (u.company) name += ` | ${u.company}`
          return {
            value: u.id,
            text: name.trim()
          }
        })
      } catch (error) {
        console.error('error', error)
      }
    },

    async fetchActivities() {
      try {
        const token = this.$store.state.token
        const url = process.env.VUE_APP_API_URL

        const { data: { data: activities } } = await axios.get(`${url}/api/user/${this.user_id}/activities`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        this.activities = activities
      } catch (error) {
        console.error('error', error)
      }
    },

    async toggleSuspend() {
      const { id, suspend } = this.data

      const token = this.$store.state.token
      const url = process.env.VUE_APP_API_URL
      const res = await axios.post(
        `${url}/api/user/suspend`,
        {
          id,
          suspend: !suspend,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      )
      console.log(res.data)
      this.data.suspend = !suspend
    },
    goToRep(id) {
      this.$router.push({ name: 'usps-show', params: { id: id } })
      // window.location = `/usps/${id}`
    },

    copyToClipboard(text) {
      const textarea = document.createElement('textarea');
      textarea.value = text;
      textarea.style.position = 'fixed';  // Avoid scrolling to bottom
      document.body.appendChild(textarea);
      textarea.focus();
      textarea.select();
      try {
        document.execCommand('copy');
        this.$emit('copied', text);
        this.$root.snackbar.show({ message: 'Copied!', color: 'success' });
      } catch (err) {
        console.error('Failed to copy: ', err);
      }
      document.body.removeChild(textarea);
    },

    copyToClipboard(text) {
      const textarea = document.createElement('textarea');
      textarea.value = text;
      textarea.style.position = 'fixed';  // Avoid scrolling to bottom
      document.body.appendChild(textarea);
      textarea.focus();
      textarea.select();
      try {
        document.execCommand('copy');
        this.$emit('copied', text);
        this.$root.snackbar.show({ message: 'Copied!', color: 'success' });
      } catch (err) {
        console.error('Failed to copy: ', err);
      }
      document.body.removeChild(textarea);
    },

    copyToClipboard(text) {
      const textarea = document.createElement('textarea');
      textarea.value = text;
      textarea.style.position = 'fixed';  // Avoid scrolling to bottom
      document.body.appendChild(textarea);
      textarea.focus();
      textarea.select();
      try {
        document.execCommand('copy');
        this.$emit('copied', text);
        this.$root.snackbar.show({ message: 'Copied!', color: 'success' });
      } catch (err) {
        console.error('Failed to copy: ', err);
      }
      document.body.removeChild(textarea);
    },

    copyToClipboard(text) {
      const textarea = document.createElement('textarea');
      textarea.value = text;
      textarea.style.position = 'fixed';  // Avoid scrolling to bottom
      document.body.appendChild(textarea);
      textarea.focus();
      textarea.select();
      try {
        document.execCommand('copy');
        this.$emit('copied', text);
        this.$root.snackbar.show({ message: 'Copied!', color: 'success' });
      } catch (err) {
        console.error('Failed to copy: ', err);
      }
      document.body.removeChild(textarea);
    },

    copyToClipboard(text) {
      const textarea = document.createElement('textarea');
      textarea.value = text;
      textarea.style.position = 'fixed';  // Avoid scrolling to bottom
      document.body.appendChild(textarea);
      textarea.focus();
      textarea.select();
      try {
        document.execCommand('copy');
        this.$emit('copied', text);
        this.$root.snackbar.show({ message: 'Copied!', color: 'success' });
      } catch (err) {
        console.error('Failed to copy: ', err);
      }
      document.body.removeChild(textarea);
    },

    getCode(item) {
      if (item.value === 'sbr') return 'SBR'
      let _service_classes = JSON.parse(localStorage.getItem('service_classes'))
      const service = _service_classes.find(s => s.id === item.value)
      return service.code
    },

    async fetchUser() {
      try {
        this.loading = true
        if (this.$root.progressbar) {
          this.$root.progressbar.show()
        }

        const token = this.$store.state.token
        const url = process.env.VUE_APP_API_URL

        // Service Classes
        let _service_classes = JSON.parse(localStorage.getItem('service_classes'))

        if (!_service_classes) {
          const { data: service_classes } = await axios.get(`${url}/api/service-classes`, {
            headers: { Authorization: `Bearer ${token}` },
          })
          localStorage.setItem('service_classes', JSON.stringify(service_classes))
          _service_classes = service_classes
        }

        this.service_classes = _service_classes.map(s => {
          return { value: s.id, text: s.name }
        })

        this.service_classes.push({ value: 'sbr', text: 'Scan-Based Return' })

        this.selectedClasses = this.service_classes.map(s => s.value)
        this.fetch_service_classes = _service_classes

        // User Details
        const {
          data: { user, reps },
        } = await axios.get(`${url}/api/user/${this.user_id}`, {
          headers: { Authorization: `Bearer ${token}` },
        })

        this.user = user

        let title = 'Accounts'
        if (this.user.name) {
          title = [this.user.company || '', this.user.name || '']
          title[0] = title[0].split(' ').slice(0, 2).join(' ')
          title[1] = title[1].split(' ')[0]
          title = title.filter((name) => name).join(' - ')
        }
        this.pageTitle = title;

        this.data = {
          id: user.id,
          suspend: user.suspend,
          shipper_id: user.shipper_id,
          name: user.name || '',
          company: user.company || '',
          address: `${user.address || ''} ${user.postal_code || ''}`,
          email: user.email || '',
          phone_number: user.phone || '',
          integrations: user.integrations,
          // printed: 'No',
          // email_verified_at: user.email_verified_at ? 1 : 0,
          created_at: user.created_at,
          last_login: user.last_login,
          // balance: data,
          survey: user.survey || '',
        }

        this.notes = user.notes?.replace(/(?:\r\n|\r|\n)/g, '<br />') || ''
        const sign_up = moment(user.created_at)

        let sub = {
          plan: user.plan_price ? 'Enterprise' : 'Pro',
          sign_up: sign_up.isValid() ? sign_up.format('lll') : 'Not Subscribed',
          payment_type: user.plan_price ? 'Enterprise' : 'Pro',
          total_billed: '$0',
        }

        this.subscription = sub
        // this.label_printed = user.printed ? user.printed.toLocaleString('en-US') : 0
        // this.postage_balance = user.postage_balance ?`$${user.postage_balance.toLocaleString('en-US')}` : '$0'
        this.postage_balance = '$0'
        this.postage_spent = user.total_postage_spent ? `$${user.total_postage_spent.toLocaleString('en-US')}` : '$0'
        this.refunded = user.voided ? `$${user.voided.toLocaleString('en-US')}` : '$0'
        this.total_insurance = user.shipsurance_balance ? `$${user.shipsurance_balance.toLocaleString('en-US')}` : '$0'

        this.reps = reps

        // First Labels
        const { data: summary } = await axios.get(`${url}/api/user/${this.user_id}/summary`, {
          headers: { Authorization: `Bearer ${token}` },
        })

        this.first_labels = await Promise.all(
          summary
            .filter(d => d.first_label_id)
            .map(async l => {
              const { data } = await axios.get(`${url}/api/order/${l.first_label_id}`, {
                headers: { Authorization: `Bearer ${token}` },
              })
              let service_class = { name: 'Scan-Based Return' }
              if (l.code !== 'SBR') {
                service_class = _service_classes.find(sc => sc.code === l.code)
              }
              return {
                id: l.first_label_id,
                name: service_class?.name ?? l.code,
                pdf: `${url}${data.signedURL}`,
              }
            }),
        )

        await this.fetchData()
        await this.fetchPostageBalance()
      } catch (error) {
        console.error('user error', error)
      } finally {
        this.loading = false
        if (this.$root.progressbar) {
          this.$root.progressbar.hide()
        }
      }
    },

    async fetchPostageBalance() {
      try {
        this.loading = true
        this.postage_loading = true
        if (this.$root.progressbar) {
          this.$root.progressbar.show()
        }
        //* Postage Balance
        const integ_url = process.env.VUE_APP_INTEG_API_URL
        const { data } = await axios.get(`${integ_url}/user-balance`, {
          params: { uid: this.user_id },
        })

        this.postage_balance =
          data && data.availableBalance ? `$${data.availableBalance.toLocaleString('en-US')}` : '$0'
      } catch (error) {
        console.error('error', error)
      } finally {
        this.loading = false
        this.postage_loading = false
        if (this.$root.progressbar) {
          this.$root.progressbar.hide()
        }
      }
    },

    async fetchData(search = '') {
      try {
        this.loading = true
        if (this.postage_loading == false && this.$root.progressbar) {
          this.$root.progressbar.show()
        }
        this.searchTerm = search

        const token = this.$store.state.token
        const url = process.env.VUE_APP_API_URL

        let params = {
          sbr: true,
          user_id: this.user_id,
          start_date: moment.tz(this.dateRange.startDate, this.getTz()).startOf('day').toISOString(),
          end_date: moment.tz(this.dateRange.endDate, this.getTz()).endOf('day').toISOString(),
          service_classes: this.selectedClasses,
        }

        if (!this.selectedClasses.includes('sbr')) {
          delete params.sbr
        }

        // Graph
        const { data: summary } = await axios.get(`${url}/api/user/${this.user_id}/summary`, {
          headers: { Authorization: `Bearer ${token}` },
          params: params,
        })

        let graph = summary.map(s => {
          let service_class = { name: 'Scan-Based Return' }
          if (s.code !== 'SBR') {
            service_class = this.fetch_service_classes.find(sc => sc.code === s.code)
          }
          return {
            name: service_class.name ?? s.code,
            code: s.code,
            spend: s.total_spent ?? 0,
            pieces: s.total_labels ?? 0,
            insurance: s.insurance_spent ?? 0,
            revenue: s.total_revenue ?? 0,
          }
        })

        // graph.push({
        //   name: 'Returns',
        //   code: 'Returns',
        //   spend: 0,
        //   pieces: this.user.returns,
        //   revenue: 0,
        // })

        this.series = [
          { name: 'Spend', data: graph.map(g => g.spend) },
          { name: 'Pieces', data: graph.map(g => g.pieces) },
          { name: 'Insurance', data: graph.map(g => g.insurance) },
          { name: 'Revenue', data: graph.map(g => g.revenue) },
        ]
        this.chartOptions = {
          chart: { type: 'bar', height: 350 },
          colors: ['#0bb4ff', '#50e991', '#e6d800', '#9b19f5', '#ffa300', '#dc0ab4', '#b3d4ff', '#00bfa0'],
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: '55%',
              endingShape: 'rounded',
            },
          },
          dataLabels: { enabled: false },
          stroke: {
            show: true,
            width: 2,
            colors: ['transparent'],
          },
          xaxis: { categories: graph.map(g => g.code) },
          fill: { opacity: 1 },
        }

        // Services
        // this.services = graph.map(s => {
        //   console.log(s, 's')
        //   return {
        //     name: s.name,
        //     code: s.code,
        //     spend: s.spend ? `$${s.spend.toLocaleString('en-US')}` : '$0',
        //     insurance: s.insurance ? `$${s.insurance.toLocaleString('en-US')}` : '$0',
        //     pieces: s.pieces ?? 0,
        //     revenue: s.revenue ? `$${s.revenue.toLocaleString('en-US')}` : '$0',
        //   }
        // })

        this.services = this.desiredOrder.map(desiredService => {
          const service = graph.find(s => s.name === desiredService)
          console.log(service, 'this is the service')
          if (service) {
            return {
              name: service.name,
              code: service.code,
              spend: service.spend ? `$${service.spend.toLocaleString('en-US')}` : '$0',
              insurance: service.insurance ? `$${service.insurance.toLocaleString('en-US')}` : '$0',
              pieces: service.pieces ?? 0,
              revenue: service.revenue ? `$${service.revenue.toLocaleString('en-US')}` : '$0',
            }
          } else {
            // Return default values for a missing service
            return {
              name: desiredService,
              code: '',
              spend: '$0',
              insurance: '$0',
              pieces: 0,
              revenue: '$0',
            }
          }
        })

        this.showChart = true

        if (_.isEmpty(this.options)) {
          return
        }

        const { itemsPerPage, page, sortBy, sortDesc } = this.options

        if (!sortBy[0]) {
          sortBy[0] = 'created_at'
          sortDesc[0] = true
        }

        params = Object.assign({}, params, {
          kw: search,
          limit: itemsPerPage,
          page: page,
          order_by: sortBy[0] ? sortBy[0] : 'created_at',
          order_direction: sortDesc[0] ? 'desc' : 'asc',
        })

        const {
          data: { meta, data },
        } = await axios.get(`${url}/api/order`, {
          headers: { Authorization: `Bearer ${token}` },
          params: params
        })

        this.orderPage = meta.current_page
        this.orderPageCount = meta.last_page
        this.orderTotalItems = meta.total

        this.orders = data.map(o => {
          return {
            id: o.id,
            tracking: o.tracking_id || o.return_tracking_id,
            order_number: o.order_number,
            marketplace: o.marketplace,
            code: o.code,
            insurance_price: o.insurance_price ? `$${o.insurance_price.toLocaleString('en-US')}` : '$0',
            rate: o.rate ? `$${o.rate.toLocaleString('en-US')}` : '$0',
            signature: o.tracking_price ? `$${o.tracking_price.toLocaleString('en-US')}` : '$0',
            surcharge: o.surcharge ? `$${o.surcharge.toLocaleString('en-US')}` : '$0',
            total_cost: o.total_price ? `$${o.total_price.toLocaleString('en-US')}` : '$0',
            shipping_service_id: o.shipping_service_id,
            return: o.order_status_id == 5 ? 1 : 0,
            created_at: o.created_at,
          }
        })
      } catch (error) {
        console.error('error', error)
      } finally {
        if (this.postage_loading == false) {
          this.loading = false
          if (this.$root.progressbar) {
            this.$root.progressbar.hide()
          }
        }
      }
    },
  }
}
</script>
